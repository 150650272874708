<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <a
      :key="'pin' + isPinned"
      :class="{ 'has-text-grey-light' : isLoading }"
      @click="setPinState"
    >
      <span class="icon is-small">
        <font-awesome-icon
          v-if="isPinned"
          :icon="['fas', 'bookmark']"
        />
        <font-awesome-icon
          v-else
          :icon="['fas', 'thumbtack']"
        />
      </span>
      <span v-if="showLabel && label">{{label}}</span>
    </a>
  </transition>
</template>

<script>
import pinProvider from '@/providers/pin'

export default {
  props: {
    itemId: {
      default: 0,
      type: Number
    },

    locationId: {
      default: 0,
      type: Number
    },

    type: {
      default: '',
      type: String
    },

    showLabel: {
      default: true,
      type: Boolean
    }
  },

  data() {
    return {
      pin: null,
      typeId: this.getPinType(),
      isLoading: false
    }
  },

  computed: {
    isPinned() {
      let isPinned = false

      if (this.pin && this.pin.Id > 0) {
        isPinned = true
      }

      return isPinned
    },

    label() {
      return this.isPinned ? this.$t('General.Unpin') : this.$t('General.Pin')
    }
  },

  created() {
    this.getIsPinnedData()
  },

  methods: {
    getPinType() {
      let self = this
      let typeId = 0

      switch (this.type.toLowerCase()) {
        case 'reservation':
          typeId = 1
          break
        case 'option':
          typeId = 2
          break
        case 'space':
          typeId = 3
          break
        case 'invoice':
          typeId = 5
          break
        case 'company':
          typeId = 6
          break
        case 'customer':
          typeId = 7
          break
        case 'voucher':
          typeId = 8
          break
        case 'chat':
          typeId = 9
          break
        case 'quote':
          typeId = 10
          break
        case 'question':
          typeId = 11
          break
        case 'article':
          typeId = 12
          break
        case 'event':
          typeId = 13
          break
        case 'job':
          typeId = 14
          break
        case 'group':
          typeId = 15
          break
      }

      return typeId
    },

    getIsPinnedData() {
      let self = this
      if (!this.isLoading) {
        this.isLoading = true
        pinProvider.methods.checkIsPinned(this.locationId, this.typeId, this.itemId)
          .then(response => {
            if (response.status === 200) {
              this.pin = response.data
            }
          })
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    setPinState() {
      if (!this.isLoading) {
        this.isLoading = true
        if (this.isPinned) {
          this.unpinItem()
        } else {
          this.pinItem()
        }
      }
    },

    pinItem() {
      let self = this
      let pin = {
        LocationId: this.locationId,
        TypeId: this.typeId,
        ItemId: this.itemId
      }
      pinProvider.methods.pinItem(this.locationId, pin)
        .then(response => {
          if (response.status === 200) {
            this.pin = response.data
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    },

    unpinItem() {
      let self = this
      pinProvider.methods.deletePin(this.pin.Id)
        .then(response => {
          if (response.status === 200) {
            this.pin = null
          }
        })
        .finally(() => {
          self.isLoading = false
        })
    }
  }
}
</script>