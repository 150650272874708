import axios from "axios";
import store from "../store";

export default {
  methods: {
    async getLocationPins(locationId = 0) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/pin/location/${locationId}`,
        {
          params: {
            locationId: locationId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async checkIsPinned(locationId = 0, typeId = 0, itemId = 0) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/pin/pinned`,
        {
          params: {
            locationId: locationId,
            typeId: typeId,
            itemId: itemId
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async pinItem(locationId, pin) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/pin`,
        JSON.stringify(pin),
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async deletePin(pinId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/pin/${pinId}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    }
  }
};
